import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App" style={{
      backgroundColor: '#ffffff',
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '2rem'
    }}>
      <header style={{
        textAlign: 'center',
        maxWidth: '800px',
        width: '100%',
        margin: '0 auto'
      }}>
        <img 
          src="/images/JM-Rehab-logo.jpg"
          alt="JM Rehab Clinic Logo"
          style={{
            width: '200px',
            maxWidth: '80%',
            marginBottom: '2rem'
          }}
        />
        <h1 style={{
          color: '#333',
          fontSize: 'clamp(1.5rem, 4vw, 2.5rem)',
          marginBottom: '1.5rem'
        }}>
          Website Under Construction
        </h1>
        <div style={{
          backgroundColor: 'white',
          padding: '2rem',
          borderRadius: '10px',
          boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
          margin: '0 1rem'
        }}>
          <p style={{
            color: '#555',
            fontSize: 'clamp(1rem, 2vw, 1.2rem)',
            lineHeight: '1.6',
            marginBottom: '1.5rem'
          }}>
            Our website is currently under development. Our dedicated team is working hard to bring you a better online experience where you can learn about our rehabilitation and treatment services. We appreciate your patience during this time.
          </p>
          <p style={{
            color: '#555',
            fontSize: 'clamp(1rem, 2vw, 1.2rem)',
            lineHeight: '1.6',
            marginBottom: '1.5rem'
          }}>
            In the meantime, please feel free to contact us at +1 778 956 9061 to schedule an appointment or learn more about our services. We look forward to helping you achieve your wellness goals.
          </p>
        </div>
      </header>
    </div>
  );
}

export default App;
